<template>
  <div class="reset-password">
    <LoadingAllScreen :is-loading.sync="loading" :scroll-lock="false"/>
    <ForgotPasswordModal
      @show-modal="closeModal($event)"
      :showing-modal="showModal"/>
    <Content class="reset-password-content">
      <span class="h4 text-center mb-6 title-text">Forgot password</span>
      <h5 class="text-center mb-6 text-content">Tell us your email address and we will
        get you back on track in no time.</h5>
      <form class="form">
        <!-- <InputTel
          v-model="formData.phone"
          :diaCode="diaCode"
          :validPhone="$v.formData.phone"
          :placeHolder="configEnv.onboarding.placeholderPhone"
          :defaultCountry="configEnv.onboarding.defaultStatePhone"
          @onDiaCode="countryChanged"
        /> -->
        <BaseInput
          class="form-group"
          :hasError="$v.formData.email.$error"
          :placeholder="configEnv.onboarding.emailPlaceHolder"
          v-model="formData.email"
        >
<!--          <template slot="error-message">-->
<!--            <span v-if="!$v.formData.email.required">Field is required</span>-->
<!--            <span v-else-if="!$v.formData.email.mustBeCool">Check correct email</span>-->
<!--          </template>-->
        </BaseInput>
        <div class="form-group button-group form-group_submit">
          <button
            class="button button_theme-default button_disabled left-button"
            @click.prevent="resetPassword"
          >
            Reset Password
          </button>
          <button
            class="button button_theme-default button_disabled small-button right-button"
            @click.prevent="login"
          >
            Login
          </button>
        </div>
      </form>
    </Content>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import configEnv from '@configEnv';
import ForgotPasswordModal from '@components/Modals/ForgotPasswordModal.vue';

const { required } = require('vuelidate/lib/validators');

const mustBeCool = (emailValid) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(emailValid).toLowerCase());
};

export default {
  components: { ForgotPasswordModal },
  mixins: [validationMixin],
  validations: {
    formData: {
      // phone: {
      //   required,
      // },
      email: {
        required,
        mustBeCool,
      },
    },
  },
  data: () => ({
    configEnv,
    showModal: false,
    formData: {
      // phone: null,
      email: null,
    },
    // diaCode: '',
    loading: false,
  }),
  computed: {
    // getClassByLengthCountryCode() {
    //   return `code-length-${this.diaCode.length}`;
    // },
  },
  watch: {
    // formData: {
    //   // eslint-disable-next-line no-unused-vars
    //   handler() {
    //     if (this.formData.phone.length > 5) {
    //       this.updatePhoneData();
    //     }
    //   },
    //   deep: true,
    // },
  },
  methods: {
    // updatePhoneData() {
    //   clearTimeout(this.updatedTimeOut);
    //   this.updatedTimeOut = setTimeout(() => {
    //     if (this.formData.phone && this.diaCode) {
    //       this.formData.phone = this.formData.phone.replace(`+${this.diaCode}`, '');
    //     }
    //   }, 50);
    // },
    // countryChanged(data) {
    //   this.diaCode = data.dialCode;
    // },
    closeModal(value) {
      this.showModal = value;
    },
    async resetPassword() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        // const phone = `+${this.diaCode}${this.formData.phone}`.replace(/\s/g, '');
        try {
          await this.$store.dispatch('auth/resetPasswordRequest', { email: this.formData.email });
          this.loading = false;// this.$router.push('enter-security-code');
          // this.$router.push('create-new-password');
          //   notify("Please check your email to upd")
          this.showModal = true;
        } catch (error) {
          this.loading = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .reset-password {
    margin-top: 85px;
  }
  .reset-password-content {
    text-align: center;
    padding-left: 25px;
    padding-right: 25px;
  }
  .text-content {
    font-family: $newDefaultFont;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #222222;
    margin-top: 15px;
    margin-bottom: 15px;
    padding-right: 10px;
    padding-left: 10px;
  }
  .title-text {
    font-family: $newDefaultFont;
    font-weight: bold;
    font-size: 32px;
    line-height: 36px;
    color: #320959;
  }
  .button-group {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    font-size: 20px;
    font-family: $newDefaultFont;
    margin-top: 45px;
  }
  .right-button {
    padding: 12px 61px;
    border: 1px solid #FF5151;
    box-sizing: border-box;
    border-radius: 5px;
    background: #FF5151;
  }
  .left-button {
    padding: 12px 14px;
    background: #7811C9;
    border: 1px solid #7811C9;
    box-sizing: border-box;
    border-radius: 5px;
  }
</style>
