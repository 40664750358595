<template>
  <v-dialog
    v-model="dialog"
    width="480"
    content-class="custom-dialog-forgot-password"
  >
    <v-card color="#F3F4F6">
      <v-card-title class="text-h5 dialog-forgot-password-title">
        <v-spacer/>
        <img :src="sentIcon" alt="sent-icon"/>
        <div class="title-text-forgot">
          Reset Password Link Sent!
        </div>
        <v-spacer/>
      </v-card-title>
      <v-card-text>
        <div class="dialog-content">If the e-mail you have entered exists in our system,
          we will send a link to reset your password in a few seconds</div>
        <div>
          <button @click="redirectToLogin" class="button-forget-password">Back to Login</button>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import sentIcon from '@assets/sent-message-icon.svg';

export default {
  name: 'ForgotPasswordModal',
  data: () => ({
    sentIcon,
    dialog: true,
  }),
  props: {
    showingModal: {
      type: Boolean,
    },
  },
  methods: {
    redirectToLogin() {
      this.$router.push('login');
    },
  },
  watch: {
    showingModal: {
      immediate: true,
      handler(val) {
        this.dialog = val;
      },
    },
    dialog(value) {
      this.$emit('show-modal', value);
    },
  },
};
</script>

<style lang="scss">
  .custom-dialog-forgot-password {
    max-height: 100% !important;
    margin: 0;
    overflow: hidden;
    font-family: $newDefaultFont;
    border-radius: 30px;
    text-align: center;
  }
  .dialog-forgot-password-title {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    line-height: 36px;
    color: #320959;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }
  .title-text-forgot {
    width: 100%;
    margin-top: 15px;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #320959;
  }
  .dialog-content {
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #222222;
  }
  .dialog-sub-title {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #320959;
    text-align: center;
    margin-top: 75px;
  }
  .button-forget-password {
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #FF5151;
    margin-top: 10px;
    margin-bottom: 15px;
  }
</style>
